import { ref, readonly } from 'vue';

const scrollingDownDetectionBlocked = ref(false);
export function useHeaderState() {
  const temporarilyBlockScrollingDownDetection = duration => {
    scrollingDownDetectionBlocked.value = true;
    setTimeout(() => {
      scrollingDownDetectionBlocked.value = false;
    }, duration);
  };

  return {
    scrollingDownDetectionBlocked: readonly(scrollingDownDetectionBlocked),
    temporarilyBlockScrollingDownDetection,
  };
}
