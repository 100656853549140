// @ts-check
import axios from 'axios';
import { paths } from '@utils/settings';
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { getHtmlLanguage } from '@utils/various';

/**
 * Resolves a path for an object
 *
 * @param {*} path object key
 * @param {*} object object
 * @returns found value
 */
function resolvePath(path, object) {
  return path.split('.').reduce((o, i) => {
    if (o && o[i]) return o[i];

    return undefined;
  }, object);
}

/**
 * Is used to make sure all returns from 'labels/get'
 * are formatted in the same way
 *
 * @returns {{label: string, language: string}}
 */
function formatResult({ label, language }) {
  return { label, language };
}

class LabelsFetcher {
  constructor(path) {
    this.path = path;
  }
  fetch() {
    return new Promise((resolve, reject) => {
      return axios
        .get(this.path, {
          responseType: 'json',
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export const useLabelsStore = defineStore('labels', () => {
  const isInitialized = ref(false);
  const labels = ref(undefined);

  const htmlLanguage = getHtmlLanguage();
  const fallbackLanguage = 'de';
  const activeLanguage = computed(() => {
    return htmlLanguage || fallbackLanguage;
  });

  const initialize = labelsObject => {
    labels.value = labelsObject;
    isInitialized.value = true;
  };

  const fetcher = new LabelsFetcher(paths.labels);
  fetcher.fetch().then(({ labels }) => {
    initialize(labels);
  });

  /**
   * Get Label
   *
   * @param {object} query
   * @param {string=} query.lang
   * @param {string} query.path
   *
   * @returns {{label: string, language: string}}
   */
  const get = query => {
    let language = query.lang || activeLanguage.value;

    if (!isInitialized.value) {
      return formatResult({
        label:
          language === 'de' ? 'Text wird geladen ...' : 'Fetching label ...',
        language: language === 'de' ? 'de' : 'en',
      });
    }

    let label = resolvePath(query.path, labels.value[language]);

    if (!label) {
      label = resolvePath(query.path, labels.value[fallbackLanguage]);
      language = fallbackLanguage;
    }

    if (isInitialized.value && !label) {
      throw Error(
        `label '${query.path}' not found for language '${language}'!`
      );
    }

    return formatResult({ label, language });
  };

  return { activeLanguage, get };
});
