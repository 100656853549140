import { share } from 'pinia-shared-state';

const sessions = [];

export const shareStore = (store, keys, config = { initialize: true }) => {
  keys.forEach(key => {
    const session = share(key, store, config);
    sessions.push(session);
  });
};

export const unshareAll = () => {
  sessions.forEach(session => {
    const { unshare } = session;
    unshare();
  });
};
