import { InvalidTargetError } from '@js/utils/errors';

const classes = {
  SOURCE: 'is-click-redirect-source',
  TARGET: 'is-click-redirect-target',
  SOURCE_TARGET_HOVER: 'is-click-redirect-source--target-has-hover',
  SOURCE_TARGET_FOCUS: 'is-click-redirect-source--target-has-focus',
  TARGET_SOURCE_HOVER: 'is-click-redirect-target--source-has-hover',
  TARGET_SOURCE_FOCUS: 'is-click-redirect-target--source-has-focus',
};
Object.freeze(classes);

class ClickRedirectTarget {
  constructor(element, sourceElement) {
    this.onMouseover = this.onMouseover.bind(this);
    this.onMouseout = this.onMouseout.bind(this);
    this.onFocusin = this.onFocusin.bind(this);
    this.onFocusout = this.onFocusout.bind(this);
    this.onSourceMouseover = this.onSourceMouseover.bind(this);
    this.onSourceMouseout = this.onSourceMouseout.bind(this);
    this.onSourceFocusin = this.onSourceFocusin.bind(this);
    this.onSourceFocusout = this.onSourceFocusout.bind(this);

    this.element = element;
    this.sourceElement = sourceElement;
  }

  click() {
    return new Promise((resolve, reject) => {
      if (!this.element) return reject(new InvalidTargetError());

      this.element.click();
      resolve();
    });
  }

  activate() {
    this.element.classList.add(classes.TARGET);
    this.sourceElement.classList.add(classes.SOURCE);

    this.element.addEventListener('mouseover', this.onMouseover);
    this.element.addEventListener('mouseout', this.onMouseout);
    this.element.addEventListener('focusin', this.onFocusin);
    this.element.addEventListener('focusout', this.onFocusout);

    this.sourceElement.addEventListener('mouseover', this.onSourceMouseover);
    this.sourceElement.addEventListener('mouseout', this.onSourceMouseout);
    this.sourceElement.addEventListener('focusin', this.onSourceFocusin);
    this.sourceElement.addEventListener('focusout', this.onSourceFocusout);
  }

  deactivate() {
    this.element.classList.remove(classes.TARGET);
    this.sourceElement.classList.remove(classes.SOURCE);

    this.sourceElement.classList.remove(classes.SOURCE_TARGET_HOVER);
    this.sourceElement.classList.remove(classes.SOURCE_TARGET_FOCUS);

    this.element.classList.remove(classes.TARGET_SOURCE_HOVER);
    this.element.classList.remove(classes.TARGET_SOURCE_FOCUS);

    this.element.removeEventListener('mouseover', this.onMouseover);
    this.element.removeEventListener('mouseout', this.onMouseout);
    this.element.removeEventListener('focusin', this.onFocusin);
    this.element.removeEventListener('focusout', this.onFocusout);

    this.sourceElement.removeEventListener('mouseover', this.onSourceMouseover);
    this.sourceElement.removeEventListener('mouseout', this.onSourceMouseout);
    this.sourceElement.removeEventListener('focusin', this.onSourceFocusin);
    this.sourceElement.removeEventListener('focusout', this.onSourceFocusout);
  }

  onMouseover() {
    this.sourceElement.classList.add(classes.SOURCE_TARGET_HOVER);
  }

  onMouseout() {
    this.sourceElement.classList.remove(classes.SOURCE_TARGET_HOVER);
  }

  onFocusin() {
    this.sourceElement.classList.add(classes.SOURCE_TARGET_FOCUS);
  }

  onFocusout() {
    this.sourceElement.classList.remove(classes.SOURCE_TARGET_FOCUS);
  }

  onSourceMouseover() {
    this.element.classList.add(classes.TARGET_SOURCE_HOVER);
  }

  onSourceMouseout() {
    this.element.classList.remove(classes.TARGET_SOURCE_HOVER);
  }

  onSourceFocusin() {
    this.element.classList.add(classes.TARGET_SOURCE_FOCUS);
  }

  onSourceFocusout() {
    this.element.classList.remove(classes.TARGET_SOURCE_FOCUS);
  }

  static Query(sourceElement, selector, parent) {
    return new Promise((resolve, reject) => {
      const baseElement = parent ? sourceElement.closest(parent) : document;
      const target = baseElement.querySelector(selector);

      if (!target) return reject(new InvalidTargetError());

      const clickRedirectTarget = new ClickRedirectTarget(
        target,
        sourceElement
      );

      return resolve(clickRedirectTarget);
    });
  }
}
export class ClickRedirect {
  constructor(element, config) {
    this.element = element;
    this.updateConfig(config);
    this.onClick = this.onClick.bind(this);
    this.isActive = false;
  }
  updateConfig({ selector, isDisabled, parent }) {
    this.parent = parent || undefined;
    this.selector = selector || undefined;
    this.isDisabled = isDisabled || false;

    this.updateTarget();
  }
  async updateTarget() {
    const target = await ClickRedirectTarget.Query(
      this.element,
      this.selector,
      this.parent
    ).catch(error => {
      if (error.name === 'InvalidTargetError') return;
      throw error;
    });

    if (target?.element === this.target?.element) return;
    if (this.isActive) this.deactivateTarget();
    this.target = target;
    if (this.isActive) this.activateTarget();
  }

  deactivateTarget() {
    this.target?.deactivate();
  }

  activateTarget() {
    this.target?.activate();
  }

  activate() {
    if (this.isActive) return;
    this.isActive = true;
    this.element.addEventListener('click', this.onClick);
    this.element.classList.add(classes.SOURCE);
    this.activateTarget();
  }
  deactivate() {
    if (!this.isActive) return;
    this.isActive = false;
    this.element.removeEventListener('click', this.onClick);
    this.element.classList.remove(classes.SOURCE);
    this.deactivateTarget();
  }
  onClick(event) {
    if (this.isDisabled) return;

    this.updateTarget();

    this.target
      .click()
      .then(() => event.preventDefault())
      .catch(error => {
        if (error.name === 'InvalidTargetError') return;
        throw error;
      });
  }
}
