<template>
  <header ref="headerElement" class="header" :class="classList" v-bind="$attrs">
    <div ref="elementHeaderInner" class="header__inner spacer-horizontal">
      <slot />
      <div id="nav-burger-target" class="header__nav-burger-target" />
    </div>
  </header>
  <div ref="boundingElement" :style="boundingStyle"></div>
</template>

<script>
export default {
  compatConfig: {
    INSTANCE_ATTRS_CLASS_STYLE: false,
  },
};
</script>

<script setup>
import { animationFrame } from '@utils/animation';
import {
  computedEager,
  useElementBounding,
  useWindowScroll,
} from '@vueuse/core';
import { ref, computed, watch, provide, readonly } from 'vue';
import { useHeaderState } from '@/components/organisms/header/use-header-state';

const boundingElement = ref(undefined);
const headerElement = ref(undefined);

const { y } = useWindowScroll();
const { height } = useElementBounding(headerElement);
const { bottom } = useElementBounding(boundingElement);
const isFixed = computedEager(() => {
  return bottom.value <= 0;
});
provide('header/isFixed', readonly(isFixed));

const isTransitionDisabled = ref(true);

watch(isFixed, async () => {
  isTransitionDisabled.value = true;
  await animationFrame();
  isTransitionDisabled.value = false;
});

const lastY = ref(y.value);
const isScrollingDown = ref(true);
const { scrollingDownDetectionBlocked } = useHeaderState();
watch(y, y => {
  if (!scrollingDownDetectionBlocked.value) {
    isScrollingDown.value = lastY.value < y;
  }
  lastY.value = y;
});

const remoteClassList = ref({});
const classList = computed(() => {
  return {
    'header--is-fixed': isFixed.value,
    'header--is-fixed-visible': !isScrollingDown.value,
    'header--is-fixed-transition-disabled': isTransitionDisabled.value,
    ...remoteClassList.value,
  };
});

const addRemoteClass = className => {
  remoteClassList.value[className] = true;
};
const removeRemoteClass = className => {
  remoteClassList.value[className] = false;
};
provide('header/addClass', addRemoteClass);
provide('header/removeClass', removeRemoteClass);

const boundingStyle = computed(() => {
  return isFixed.value
    ? {
        height: `${height.value}px`,
      }
    : {};
});
</script>
