import { computed, reactive, readonly } from 'vue';

const state = reactive({
  instances: {},
});

export function useQuiz({ id }) {
  if (!state.instances[id]) {
    state.instances[id] = {
      answers: {},
      eleminationAnswer: undefined,
    };
  }

  const instance = computed(() => {
    return state.instances[id];
  });

  const giveAnswer = (itemId, answer, isElemination = false) => {
    if (isElemination) {
      instance.value.eleminationAnswer = answer;
      return;
    }

    instance.value.answers[itemId] = answer;
  };

  const getAnswer = (itemId, isElemination = false) => {
    if (isElemination) {
      return instance.value.eleminationAnswer;
    }

    return instance.value.answers[itemId];
  };

  const reset = () => {
    state.instances[id].answers = {};
    state.instances[id].eleminationAnswer = undefined;
  };

  const answers = computed(() => {
    return instance.value.answers;
  });

  const eleminationAnswer = computed(() => {
    return instance.value.eleminationAnswer;
  });

  return {
    reset,
    giveAnswer,
    getAnswer,
    answers: answers,
    eleminationAnswer: eleminationAnswer,
  };
}
