export default {
  types: {
    a: {
      epublisherType: 2,
      url: 'gourmet',
      colors: ['#e64e1b', '#e6941b', '#e64e1b', '#e6941b'],
      image: '/assets/concert-finder/geniesserin.png',
      icon: 'finder-gourmet',
    },
    b: {
      epublisherType: 1,
      url: 'adventurer',
      colors: ['#46966a', '#163626', '#46966a', '#163626'],
      image: '/assets/concert-finder/abenteurerin.png',
      icon: 'finder-adventurer',
    },
    c: {
      epublisherType: 4,
      url: 'brain',
      colors: ['#1a60f1', '#0f3681', '#1a60f1', '#0f3681'],
      image: '/assets/concert-finder/brain.png',
      icon: 'finder-brain',
    },
    d: {
      epublisherType: 3,
      url: 'curious',
      colors: ['#a052ad', '#772283', '#a052ad', '#772283'],
      image: '/assets/concert-finder/neugierige.png',
      icon: 'finder-curious',
    },
  },
  questions: [
    {
      id: '1',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 2 },
        },
        {
          id: 'b',
          value: { b: 2 },
        },
        {
          id: 'c',
          value: { c: 2 },
        },
        {
          id: 'd',
          value: { d: 2 },
        },
      ],
    },
    {
      id: '2',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
        },
        {
          id: 'b',
          value: { b: 1 },
        },
        {
          id: 'c',
          value: { c: 1 },
        },
        {
          id: 'd',
          value: { d: 1 },
        },
      ],
    },
    {
      id: '3',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
        },
        {
          id: 'b',
          value: { b: 1 },
        },
        {
          id: 'c',
          value: { c: 1 },
        },
        {
          id: 'd',
          value: { d: 1 },
        },
      ],
    },
    {
      id: '4',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 2 },
        },
        {
          id: 'b',
          value: { b: 2 },
        },
        {
          id: 'c',
          value: { c: 2 },
        },
        {
          id: 'd',
          value: { d: 2 },
        },
      ],
    },
    {
      id: '5',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
        },
        {
          id: 'b',
          value: { b: 1 },
        },
        {
          id: 'c',
          value: { c: 1 },
        },
        {
          id: 'd',
          value: { d: 1 },
        },
      ],
    },
    {
      id: '6',
      type: 'image',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
          path: '/assets/concert-finder/answer-geniesserin.jpg',
        },
        {
          id: 'b',
          value: { b: 1 },
          path: '/assets/concert-finder/answer-abenteurerin.jpg',
        },
        {
          id: 'c',
          value: { c: 1 },
          path: '/assets/concert-finder/answer-brain.jpg',
        },
        {
          id: 'd',
          value: { d: 1 },
          path: '/assets/concert-finder/answer-neugierige.jpg',
        },
      ],
    },
    {
      id: '7',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 2 },
        },
        {
          id: 'b',
          value: { b: 2 },
        },
        {
          id: 'c',
          value: { c: 2 },
        },
        {
          id: 'd',
          value: { d: 2 },
        },
      ],
    },
    {
      id: '8',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
        },
        {
          id: 'b',
          value: { b: 1 },
        },
        {
          id: 'c',
          value: { c: 1 },
        },
        {
          id: 'd',
          value: { d: 1 },
        },
      ],
    },
    {
      id: '9',
      type: 'text',
      answers: [
        {
          id: 'a',
          value: { a: 1 },
        },
        {
          id: 'b',
          value: { b: 1 },
        },
        {
          id: 'c',
          value: { c: 1 },
        },
        {
          id: 'd',
          value: { d: 1 },
        },
      ],
    },
  ],
  eliminationQuestion: {
    id: '10',
    type: 'text',
    eliminationQuestion: true,
    answers: [
      {
        id: 'a',
        value: 'a',
      },
      {
        id: 'b',
        value: 'b',
      },
      {
        id: 'c',
        value: 'c',
      },
      {
        id: 'd',
        value: 'd',
      },
    ],
  },
};
