import { computed, readonly, ref } from 'vue';
import { useQuiz } from './use-quiz';

const customWinner = ref(null);

export function useQuizResultsForConcertFinder({ quizId }) {
  const { answers, eleminationAnswer } = useQuiz({
    id: quizId.value,
  });

  const results = computed(() => {
    return Object.values(answers.value).reduce((acc, answer) => {
      Object.entries(answer?.value).forEach(([answerId, value]) => {
        if (!acc[answerId]) {
          acc[answerId] = 0;
        }
        acc[answerId] += value;
      });

      return acc;
    }, {});
  });

  const sortedResultEntries = computed(() => {
    return Object.entries(results.value).sort((a, b) => b[1] - a[1]);
  });

  const winnerList = computed(() => {
    const topResult = sortedResultEntries.value?.[0]?.[1];
    const tieEntries = sortedResultEntries.value?.filter(
      ([answerId, result]) => {
        return topResult === result;
      }
    );

    return tieEntries.map(([answerId]) => answerId);
  });

  const isTie = computed(() => {
    return winnerList.value.length > 1;
  });

  const winner = computed(() => {
    return (
      customWinner.value ||
      eleminationAnswer.value?.value ||
      winnerList.value[0]
    );
  });

  const setCustomWinner = winner => {
    customWinner.value = winner;
  };

  const unsetCustomWinner = () => {
    customWinner.value = null;
  };

  return {
    results,
    sortedResultEntries,
    winner,
    winnerList,
    setCustomWinner,
    unsetCustomWinner,
    customWinner: readonly(customWinner),
    isTie,
  };
}
