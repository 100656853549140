(function aspectRatioPolyfill() {
  if (CSS.supports('aspect-ratio: 1 / 1')) return;

  /* Aspect Ratio plugin for github.com/tomhodgins/aspect-ratio-spec
  source: https://github.com/WICG/aspect-ratio/issues/7#issuecomment-265631882
  including some custom bugfixes */

  var rules = [];

  function load() {
    // console.log('load aspect-ratio data');
    rules = [];
    // load <style> tags
    var tag = document.querySelectorAll('style');
    for (var i = 0; i < tag.length; i++) {
      parse(tag[i].innerHTML);
    }
    // load <link> tags
    var tag = document.querySelectorAll('link');
    for (var i = 0; i < tag.length; i++) {
      if (tag[i].getAttribute('rel') === 'stylesheet') {
        if (tag[i].href) {
          (function () {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', tag[i].href, true);
            xhr.send(null);
            xhr.onload = function () {
              parse(xhr.responseText);
            };
          })();
        }
      }
    }
  }

  function parse(text) {
    var css = text || '';
    css
      .replace(/\n/g, '')
      .replace(/\}/g, '}\n')
      .replace(/\s*{/g, '{')
      .replace(/^\s*/gm, '')
      .replace(
        /^(.*?)\s?{.*aspect-ratio:\s*(\d*\.?\d+)\/(\d*\.?\d+)/gm,
        function (match, $1, $2, $3) {
          var rule = [$1, $2, $3];
          rules.push(rule);
        }
      );
    apply();
  }

  function apply() {
    for (var i = 0; i < rules.length; i++) {
      var tag = document.querySelectorAll(rules[i][0]);
      for (var j = 0; j < tag.length; j++) {
        var natWidth = getRule(tag[j], 'width') || false;
        var natHeight = getRule(tag[j], 'height') || false,
          currWidth = tag[j].offsetWidth,
          currHeight = tag[j].offsetHeight,
          rWidth = rules[i][1],
          rHeight = rules[i][2];

        if (!natWidth && !natHeight) {
          tag[j].style.height = currWidth / (rWidth / rHeight) + 'px';
        }
        if (natWidth && !natHeight) {
          tag[j].style.height = currWidth / (rWidth / rHeight) + 'px';
        }
        if (!natWidth && natHeight) {
          tag[j].style.width = currHeight * (rWidth / rHeight) + 'px';
        }
      }
    }
  }

  function getRule(tag, query) {
    const hasRule = Array.from(document.styleSheets).some(sheet =>
      Array.from(sheet.cssRules).some(rule => {
        try {
          const matchesSelector = tag.matches(rule.selectorText);
          const hasProperty = rule.cssText.includes(query);
          return matchesSelector && hasProperty;
        } catch (e) {
          return false;
        }
      })
    );
    return hasRule;
  }

  document.addEventListener('DOMContentLoaded', load);
  window.addEventListener('resize', apply);

  // fix elements added after initial document load
  var timeoutId = undefined;
  window.addEventListener('DOMNodeInserted', e => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      apply();
    }, 60);
  });
})();
