import { useI18n } from 'vue-i18n';

export function useConcertFinderI18n() {
  const i18n = useI18n({
    messages: {
      de: {
        types: {
          a: {
            heading: 'Die Genießerin / Der Genießer',
            subline:
              'Du bist: romantisch verträumt, gemütlich, harmonieorientiert, empfindsam, entspannt',
            text: 'Du schätzt die harmonischen Klänge und die romantische Atmosphäre klassischer Konzerte. Für dich ist ein Abend im Konzertsaal Genuss und Entspannung, besonders wenn du ihn in angenehmer Gesellschaft verbringen kannst. Harmonie spielt für dich nicht nur im Leben, sondern auch in der Musik eine große Rolle. Hast du eine Melodie gehört, die dein Herz höherschlagen lässt, bleibt sie dir als wohlige Erinnerung im Ohr. Daten und Fakten zur Musik stehen für dich nicht im Vordergrund, du lässt dich lieber vom Klang der Musik tragen und willst im Konzert die gemeinsame Auszeit genießen.',
          },
          b: {
            heading: 'Die Abenteurerin / Der Abenteurer',
            subline:
              'Du bist: mutig, abenteuerlustig, unabhängig, risikobereit, entschlossen, leidenschaftlich',
            text: 'Du sehnst dich stets nach neuen, aufregenden Erfahrungen. Auch Musik muss dich direkt mitreißen. Für dich sind es die emotional aufgeladenen Symphonien mit großer Besetzung, die dein Herz höherschlagen lassen und dich auf ein Abenteuer der Klangvielfalt entführen. Andererseits weißt du aber auch, dass die beeindruckenden Erlebnisse manchmal hinter verschlungenen Pfaden warten und in den kleinen feinen Momenten liegen. Du lässt dich von der Neugier auf das Unbekannte leiten und suchst stets nach neuen musikalischen Horizonten.',
          },
          c: {
            heading: 'The Brain',
            subline:
              'Du bist: wissbegierig, analytisch, tiefgründig, anspruchsvoll, konzentriert, forschend, detailverliebt',
            text: '„The Brain“ – der Spitzname kommt nicht von ungefähr. Du magst dich zwar noch nicht in der Klassik auskennen, aber wenn du ein Konzert besuchst, machst du keine halben Sachen: Du willst ein Werk nicht nur hören, du willst es verstehen. Du möchtest dich durch die Musik gefordert wissen und dich auch intellektuell stimuliert fühlen. Schon gewusst, dass Mahler seine 5. Sinfonie zu Lebzeiten als „verflucht“ bezeichnet hat? „The Brain“ wissen das natürlich, denn unser Podcast zur Konzerteinführung (oder Wikipedia) hat sie schon vor Konzertbeginn auf den neuesten Stand gebracht.',
          },
          d: {
            heading: 'Die Neugierige / Der Neugierige',
            subline:
              'Du bist: aufgeschlossen, begeisterungsfähig, unvoreingenommen, aber auch vorsichtig, erkundungslustig, technikaffin',
            text: 'Für dich ist die Klassik ein faszinierendes, jedoch noch komplett unentdecktes Terrain. Behutsam wagst du dich in diese Welt, gern mit "Sicherheitsnetz“  – sei es in Form von Konzerten mit moderierten Einführungen oder einer App, die interessante Hintergrundinformationen und Hörhinweise direkt während des Konzerts auf dem Handy anzeigt, um das Werk besser zu verstehen. Technikaffin und aufgeschlossen lässt du dich von innovativen Ansätzen begeistern, die den Zugang zur Klassik erleichtern. Große Vorbereitungen sind nicht dein Ding; vielmehr magst du das unmittelbare Erleben und Eintauchen in die Welt der Klänge und Emotionen.',
          },
        },

        questions: {
          1: {
            heading:
              'Zunächst möchten wir dich gern besser kennenlernen. Neue Dinge:',
            answers: {
              a: 'Gehst du entspannt an.',
              b: 'Packst du direkt an.',
              c: 'Hinterfragst du erstmal.',
              d: 'Betrachtest du aus der Distanz.',
            },
          },
          2: {
            heading: 'Wäre dein Leben ein Film, wäre es:',
            answers: {
              a: 'Etwas Romantisches – oder eine Komödie.',
              b: 'Action/Thriller/Adventure mit vielen Wendungen. Mein Alltag hat mehr Action als Hollywood.',
              c: 'Eine Biografie, die die vielschichtigen Facetten des Lebens beleuchtet.',
              d: 'Science Fiction oder Fantasy – denn manchmal fühle ich mich wie von einem anderen Stern.',
            },
          },
          3: {
            heading:
              'Moment – bisher geht es ja gar nicht um Musik! Deshalb: Wann hörst du denn am liebsten Musik?',
            answers: {
              a: 'Ganz klar zum Entspannen! Musik senkt mein Stresslevel.',
              b: 'Wenn ich traurig oder glücklich bin – emotional passend!',
              c: 'Selten nebenbei. Ich konzentriere mich gern auf Text & Melodie.',
              d: 'Beim Sport, Haushalt, Lernen, einfach so – mein Leben ist ein Soundtrack!',
            },
          },
          4: {
            heading: 'Alles klar. Und deine aktuelle Playlist beinhaltet:',
            answers: {
              a: 'Meine zeitlosen Favoriten in einem Mix.',
              b: 'Eine musikalische Weltreise. Ich entdecke immer Neues.',
              c: 'Fast jedes Album oder jede Komposition meiner Lieblingkünstler/-künstlerinnen.',
              d: 'Empfehlungen – Spotify & Co kennen meinen Geschmack.',
            },
          },
          5: {
            heading:
              'Puh, Zeit für eine Mittagspause! Wir kochen gemeinsam, wir schnippeln die Zutaten und du:',
            answers: {
              a: 'Mischst alles nach Gefühl, denn hey – so entstehen die besten Rezepte!',
              b: 'Probierst definitiv dieses coole Gericht aus, das du auf Social Media gesehen hast.',
              c: 'Recherchierst die Top-Rezepte und hast nun die optimale Anleitung.',
              d: 'Hältst dich genau an das Rezept deiner Oma. Sicher ist sicher!',
            },
          },
          6: {
            heading:
              'Mit vollem Magen soll man ja kurz Entspannen. Welches der Bilder gefällt dir am besten:',
            answers: {
              a: 'Genießer:in',
              b: 'Abenteurer:in',
              c: 'The Brain',
              d: 'Neugierige:r',
            },
          },
          7: {
            heading:
              'Weiter geht‘s. Wenn du zu einem unserer Konzerte kommst, würdest du vorab:',
            answers: {
              a: 'Entspannen, einfach kommen und die Musik genießen.',
              b: 'Kurz interessante Key Facts googeln und mich dann überraschen lassen.',
              c: 'Mich ein bisschen über die Hintergründe zur Musik informieren.',
              d: 'Nichts tun – ich möchte lieber Infos per Moderation/App im Konzert.',
            },
          },
          8: {
            heading:
              'Stell dir vor, es ist Urlaubszeit. Welches Angebot würdest du buchen?',
            answers: {
              a: 'Einen relaxten Strandurlaub mit einem Candle Light Dinner.',
              b: 'Eine Kanu Tour auf dem Amazonas oder eine Trekking Tour durch den Himalaya.',
              c: 'Eine Guided Tour durch die Altertumsstätten Nordafrikas.',
              d: 'Eine All inclusive-Reise, bei der ich mich um nichts weiter kümmern muss.',
            },
          },
          9: {
            heading: 'Was würdest du auf eine einsame Insel mitnehmen?',
            answers: {
              a: 'Eine Hängematte, dann kann ich zumindest nachts die Sterne betrachten.',
              b: 'Ein Multitool, um die Insel zu erkunden und zu überleben.',
              c: 'Ein Boot mit eingebautem GPS-Gerät, um zurückzufahren.',
              d: 'Warte – ohne meinen besten Freund / Freundin gehe ich auf GAR KEINE Insel!',
            },
          },
          10: {
            heading:
              'Hm, bei dir fällt uns die Entscheidung schwer. Extrafrage: Welches Tier wärst du gern?',
            answers: {
              a: 'Koala',
              b: 'Tiger',
              c: 'Delfin',
              d: 'Erdmännchen',
            },
          },
        },

        pages: {
          start: {
            heading: 'Welcher Konzerttyp bin ich?',
            text: `
              <p>
                Mach den Test und finde heraus, welches Klassikkonzert du unbedingt ausprobieren
                solltest. <strong class="concert-finder-page__mark">Jetzt mit Konzerten der
                neuen Saison!</strong> Der Test dauert nicht mehr als 4 Minuten und du
                brauchst keinerlei Vorwissen im Bereich Klassik. Außerdem gibt es für jedes
                über den Konzertfinder gebuchte Ticket ein kleines Überraschungspaket vor
                dem Konzert – passend zu deinem Typ.
              </p>
            `,
          },
          feedback: {
            heading: 'Wie hat dir unser Konzertfinder gefallen?',
            text: 'Sag uns deine Meinung! Deine Rückmeldung hilft uns, unseren Konzertfinder weiter zu verbessern. Egal, ob du Vorschläge, Kritik oder Lob hast – lass es uns wissen.',
          },
          end: {
            heading: 'Puh, geschafft',
            text: `
              <p>Du hast alle Fragen bravurös beantwortet!</p>
              <p>
                Und was sollen wir sagen: Einige Antworten haben uns echt
                überrascht. ;)
              </p>
              <p>
                Unser Team aus den Fachbereichen Psychologie, Dramaturgie, Regie,
                Tontechnik, Medizin und Astrologie wertet jetzt deine Antworten aus.
              </p>
              <p>Scherz. Das macht heute natürlich unser Algorithmus.</p>
              <p>Hier kommt dein Ergebnis:</p>
            `,
          },
          goodieAd: {
            heading: 'Hol dir dein Goodie Bag!',
            text: `
            <p>
              <strong>Bevor es zu deinen Ergebnissen geht, hier noch ein kleines Extra:</strong>
            </p>
            <p>
              Wenn du ein Konzert direkt über den Konzertfinder buchst, erwartet dich am Tag der Veranstaltung ein persönliches Goodie Bag*. Du kannst es dir vor dem Konzert am Infotisch abholen. Setze dafür in deiner Buchung unter "Abfrage" einfach ein Häkchen bei "Ich buche über den Konzertfinder".
            </p>
            <p class="concert-finder-page__footnote">
              *Pro Person gibt es nur ein Goodie Bag, das dem jeweiligen Konzerttyp des am Abend stattfindenden Konzerts entspricht. Weitere Hinweise dazu gibt es in unseren FAQ.
            </p>
            `,
          },
          calendarAd: {
            heading: 'Weitere Empfehlungen:',
            text: `
              Ist noch nicht das passende Konzert dabei? Dann findest du in unserem Konzertkalender
              noch mehr Empfehlungen speziell für deinen Konzerttyp!
            `,
          },
          recommendation: {
            heading: 'Empfehlungen für dich:',
          },
        },

        other: {
          sampleOnIdagio: 'Hörprobe auf IDAGIO',
          gotoStart: 'Test starten',
          gotoFeedback:
            '<span class="concert-finder-navigation__hide-mobile">Dein </span>Feedback',
          gotoCalendar: 'Zum Konzertkalender',
          feedbackErrorNotRated: 'Bitte bewerte den Konzertfinder.',
          feedbackErrorNotSaved:
            'Es ist ein Fehler aufgetreten. Dein Feedback konnte nicht gespeichert werden. Bitte versuche es später erneut.',
          sampleTeaser:
            'PS: Erhalte einen Vorgeschmack auf unsere kommenden Konzerte. Höre dir ausgewählte Stücke an und lass dich von der Musik inspirieren.',
        },

        verbatim: {
          'Zum Konzert': 'Zum Konzert',
          'Los geht es': "Los geht's",
          'Zum Ergebnis': 'Zum Ergebnis',
          'Zu deinen Empfehlungen': 'Zu deinen Empfehlungen',
          Teilen: 'Teilen',
          Weiter: 'Weiter',
          Zurück: 'Zurück',
          Datenschutzerklärung: 'Datenschutzerklärung',
          'Test Wiederholen':
            '<span class="concert-finder-navigation__hide-mobile">Test </span>Wiederholen',
          'Zu den Hörproben': 'Zu den Hörproben',
          'Vielen Dank für Dein Feedback': 'Vielen Dank für Dein Feedback.',
          'Feedback senden': 'Feedback senden',
          'Dein Feedback': 'Dein Feedback...',
          'Du bist': 'Du bist:',
          'KI generiert': 'KI generiert',
          'Jetzt aber wirklich': 'Jetzt aber wirklich!',
        },
      },
      en: {
        types: {
          a: {
            heading: 'The Dreamer',
            subline:
              'You are: romantic, dreamy, calm, harmonious, sensitive, relaxed',
            text: 'You appreciate the harmonious sounds and romantic atmosphere of classical concerts. For you, an evening in a concert hall is enjoyable and relaxing, especially if you can spend it in pleasant company. Harmony plays an important role for you not only in life, but also in music. Once you have heard a melody that makes your heart beat faster, it stays in your ear as a comforting memory. Facts and figures about music are not your priority, you prefer to let yourself be carried away by the sound of the music and want to enjoy time  together at a concert.',
          },
          b: {
            heading: 'The Explorer',
            subline:
              'You are: Courageous, adventurous, independent, willing to take risks, determined, passionate',
            text: "You always long for new, exciting experiences. Music must carry you away instantly. For you, it's the emotionally charged symphonies with large ensembles that make your heart beat faster and take you on an adventure of a diversity of sounds. On the other hand, you also know that the most impressive experiences sometimes wait behind winding paths and lie in the small, most subtle moments. Your curiosity for the unknown is your driving force and you are always looking for new musical horizons.",
          },
          c: {
            heading: 'The Brain',
            subline:
              'You are: inquisitive, analytical, profound, demanding, focused, explorative, in love with detail',
            text: `“The Brain” - the nickname is no coincidence. You may not yet be familiar with classical music, but when you go to a concert, you don't do things by halves: You don't just want to hear a work, you want to understand it. You want to be challenged by the music and feel intellectually stimulated. Did you know that Mahler described his 5th Symphony as ‘cursed’ during his lifetime? ‘The Brain’ know this, of course, because our concert introduction podcast (or Wikipedia) has already brought them up to date before the concert begins.`,
          },
          d: {
            heading: 'The Curious One',
            subline:
              'You are: open-minded, enthusiastic, unbiased, but also cautious, eager to explore, tech-savvy',
            text: `For you, classical music is a fascinating but still completely undiscovered terrain. You venture cautiously into this world, preferably with a "safety net" - be it in the form of concerts with moderated introductions or an app that displays interesting background information and listening tips directly on your cell phone during the concert to help you understand the work even better. With an affinity for technology and an open mind, you are enthusiastic about innovative approaches that make classical music more accessible. You don't like a lot of preparation; you prefer the immediate experience and immersion in the world of sounds and emotions.`,
          },
        },

        questions: {
          1: {
            heading:
              'First of all, we would like to get to know you better. Something new:',
            answers: {
              a: 'You take a relaxed approach.',
              b: 'You tackle it straight away.',
              c: 'You question things first.',
              d: 'Look at things from a distance.',
            },
          },
          2: {
            heading: 'If your life were a movie, it would be:',
            answers: {
              a: 'A romance - or a comedy.',
              b: 'Action/thriller/adventure with lots of twists and turns. My everyday life is more action packed than Hollywood.',
              c: 'A biography that sheds light on the many facets of life.',
              d: "Science fiction or fantasy - because sometimes I feel like I'm from another planet.",
            },
          },
          3: {
            heading:
              "Wait a minute - this isn't about music yet! So, when do you prefer to listen to music?",
            answers: {
              a: 'Definitely to relax! Music lowers my stress level.',
              b: "When I'm sad or happy - it needs to fit my emotions!",
              c: 'Rarely on the side. I like to concentrate on the lyrics and melody.',
              d: 'At the gym, while doing housework, when studying, or without a special purpose - my life is a soundtrack!',
            },
          },
          4: {
            heading: 'All right. And your current playlist includes:',
            answers: {
              a: 'My all-time favorites in a mix.',
              b: "A musical journey around the world. I'm always discovering new things.",
              c: 'Almost every album or composition by my favorite artists.',
              d: 'Recommendations - Spotify & Co know my taste.',
            },
          },
          5: {
            heading:
              "Phew, time for a lunch break! Let's cook together, we'll chop the ingredients and you:",
            answers: {
              a: "Mix everything by feel, because hey - that's what makes the best recipes!",
              b: "Definitely try out that cool dish you've seen on social media.",
              c: 'Research the top recipes and now have the perfect instructions.',
              d: "Follow your grandma's recipe exactly. Better safe than sorry!",
            },
          },
          6: {
            heading:
              'Time for a short digestive break. Which picture do you like best?',
            answers: {
              a: 'The Dreamer',
              b: 'The Explorer',
              c: 'The Brain',
              d: 'The Curious One',
            },
          },
          7: {
            heading:
              "Let's move on. If you come to one of our concerts, what would you do in advance:",
            answers: {
              a: 'Relax, just come and enjoy the music.',
              b: 'Briefly google interesting key facts and then let myself be surprised.',
              c: 'Find out a bit about the background to the music.',
              d: 'Do nothing - I would rather have information via moderation/app in concert.',
            },
          },
          8: {
            heading: "Imagine it's vacation time. Which offer would you book?",
            answers: {
              a: 'A relaxing beach vacation with a candlelight dinner.',
              b: 'A canoe tour on the Amazon or a trekking tour through the Himalayas.',
              c: 'A guided tour through the ancient sites of North Africa.',
              d: "An all-inclusive trip where I don't have to worry about a thing.",
            },
          },
          9: {
            heading:
              'And finally: What would you take with you to a deserted island?',
            answers: {
              a: 'A hammock, so I can at least look at the stars at night.',
              b: 'A multitool to explore the island and survive.',
              c: 'A boat with a built-in GPS device to navigate back.',
              d: "Wait - I'm NOT going to ANY island without my best friend!",
            },
          },
          10: {
            heading:
              "Hm, we're still not sure about you. Extra question: What animal would you like to be?",
            answers: {
              a: 'Koala',
              b: 'Tiger',
              c: 'Dolphin',
              d: 'Meerkat',
            },
          },
        },

        pages: {
          start: {
            heading: 'What type of concert is for me?',
            text: `
              <p>
                Take the test and find out which classical concert you should definitely
                try out. <strong class="concert-finder-page__mark">Now with new season
                concerts!</strong>
              </p>
              <p>
                The test takes no more than 4 minutes and you don't need any previous
                knowledge of classical music. What's more, for every ticket booked via the
                concert finder, you'll receive a small surprise package before the concert
                - to suit your type.
              </p>
            `,
          },
          feedback: {
            heading: 'How did you like our concert finder?',
            text: 'Tell us what you think! Your feedback helps us to further improve our concert finder. Whether you have suggestions, criticism or praise - let us know.',
          },
          end: {
            heading: 'Phew, done!',
            text: `
              <p>You answered all the questions brilliantly!</p>
              <p>
                And what can we say: some of the answers really surprised us ;)
              </p>
              <p>
                Our team from the fields of psychology, dramaturgy, directing, sound engineering, medicine and astrology are now evaluating your answers.
              </p>
              <p>Joke aside. Our algorithm is doing that, of course.</p>
              <p>This is your result:</p>
            `,
          },
          goodieAd: {
            heading: 'Get your goodie bag!',
            text: `
            <p>
              <strong>Before we get to your results, here's a little extra:</strong>
            </p>
            <p>
              If you book a concert directly through the Concert Finder, you will receive a goodie bag* on the day of the event. You can pick it up at the info counter before the concert. Simply tick the box “I book via concert finder” under “Request” in your booking.
            </p>
            <p class="concert-finder-page__footnote">
              *There is only one goodie bag per person, depending on the type of concert. More information can be found in our FAQ.
            </p>
            `,
          },
          calendarAd: {
            heading: 'Further Recommendations:',
            text: `
              Haven't found the right concert yet? Then you'll find even more recommendations
              for your type of concert in our concert calendar!
            `,
          },
          recommendation: {
            heading: 'Recommendations for you:',
          },
        },

        other: {
          sampleOnIdagio: 'Audio sample on IDAGIO',
          gotoStart: 'Start Test',
          gotoFeedback:
            '<span class="concert-finder-navigation__hide-mobile">Your </span>Feedback',
          gotoCalendar: 'To the concert calendar',
          feedbackErrorNotRated: 'Please rate the concert finder.',
          feedbackErrorNotSaved:
            'An error occurred. Your feedback could not be saved. Please try again later.',
          sampleTeaser:
            'PS: Get a sneak preview of our upcoming concerts. Listen to selected pieces and let the music inspire you.',
        },

        verbatim: {
          'Zum Konzert': 'Go to concert',
          'Los geht es': "Let's go",
          'Zum Ergebnis': 'Show result',
          'Zu deinen Empfehlungen': 'Show recommendations',
          Teilen: 'Share',
          Weiter: 'Next',
          Zurück: 'Back',
          'KI generiert': 'AI generated',
          Datenschutzerklärung: 'Privacy Policy',
          'Test Wiederholen':
            'Repeat<span class="concert-finder-navigation__hide-mobile"> Test</span>',
          'Zu den Hörproben': 'Go to audio samples',
          'Vielen Dank für Dein Feedback': 'Thank you for your feedback.',
          'Feedback senden': 'Send Feedback',
          'Dein Feedback': 'Your Feedback...',
          'Du bist': 'You are:',
          'Jetzt aber wirklich': 'Okay, for real',
        },
      },
    },
  });

  return i18n;
}
