const handlers = {
  // TODO: maybe use matchMedia instead?
  resize: {
    immediate: true,
    passive: true,

    callback: store => {
      return event => {
        const width = window.innerWidth;
        store.commit('window/updateWidth', width);
        const height = window.innerHeight;
        store.commit('window/updateHeight', height);
      };
    },
  },

  scroll: {
    immediate: true,
    passive: true,

    callback: store => {
      return event => {
        const scroll = {
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        };
        store.commit('window/updateScroll', scroll);
      };
    },
  },
};

export default store => {
  for (const type in handlers) {
    const handler = handlers[type];

    const callback = (
      typeof handler === 'function' ? handler : handler.callback
    )(store);

    const config = {
      ...(handler.passive ? { passive: true } : {}),
    };

    window.addEventListener(type, callback, config);

    if (handler.immediate) {
      setTimeout(callback, 0);
    }
  }
};
