import settingsImported from '../../../../settings.json';
import merge from 'lodash/merge';

let env = 'development';

if (process.env.NODE_ENV === 'production') {
  env = 'production';
}

const SETTINGS_FALLBACK = Object.freeze({
  paths: {
    styleguide: '/styleguide',
    public: '/public',
    labels: settingsImported[env].paths.public + '/assets/labels/labels.json',
    concertTourGeoJson:
      settingsImported[env].paths.public + '/assets/concert-tour/world.json',
  },
});

const settingsLocal = window.$DPC_SETTINGS;

export const settings = merge(
  SETTINGS_FALLBACK,
  settingsImported[env],
  settingsLocal
);

export const paths = settings.paths;
