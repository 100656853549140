<template>
  <picture
    ref="pictureElement"
    class="picture"
    :class="{
      'picture--is-active': isActive,
      'picture--has-loaded': hasLoaded,
    }"
  >
    <source
      v-for="(source, index) in sourcesActive"
      :key="index"
      :srcset="source.srcset"
      :media="source.media"
      class="picture__source"
    />
    <img
      ref="img"
      class="picture__image"
      :src="sourceActive"
      :alt="alt"
      @load="onImageLoad"
    />
  </picture>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
import { inject, markRaw, onMounted, ref } from 'vue';
import { ResizeObserver } from '@juggle/resize-observer';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';

const UPDATE_DEBOUNCE_DELAY = 500;

const IsInvalidSource = source =>
  source.hasOwnProperty('src') ||
  (source.hasOwnProperty('media') === false &&
    source.hasOwnProperty('width') === false);

const IsValidSourceArray = sources =>
  !sources.find(source => IsInvalidSource(source));

const SOURCE_SELECTOR = {
  size: 'size',
  sizeOrientation: 'size-orientation',
  media: 'media',
};
Object.freeze(SOURCE_SELECTOR);

const SELECT_SIZE_BY = {
  width: 'width',
  height: 'height',
  auto: 'auto',
};
Object.freeze(SELECT_SIZE_BY);

export default {
  props: {
    alt: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    sources: {
      type: Array,
      validator: IsValidSourceArray,
      default() {
        return [];
      },
    },
    sourceSelector: {
      type: String,
      default: SOURCE_SELECTOR.media,
      validator(value) {
        if (!Object.values(SOURCE_SELECTOR).includes(value)) {
          throw new Error(
            `Invalid value for 'selectSizeBy': ${value}. Valid values: ${Object.values(
              SOURCE_SELECTOR
            ).join(', ')}`
          );
        }
        return Object.values(SOURCE_SELECTOR).includes(value);
      },
    },
    selectSizeBy: {
      type: String,
      default: SELECT_SIZE_BY.width,
      validator(value) {
        if (!Object.values(SELECT_SIZE_BY).includes(value)) {
          throw new Error(
            `Invalid value for 'selectSizeBy': ${value}. Valid values: ${Object.values(
              SELECT_SIZE_BY
            ).join(', ')}`
          );
        }
        return Object.values(SELECT_SIZE_BY).includes(value);
      },
    },
    copySizeBy: {
      type: String,
    },
  },

  setup(props) {
    const { src } = props;
    const loadEventHook = inject('picture/loadEventHook', () => {});
    const setSizeEventHook = inject('picture/setSizeEventHook', () => {});

    const wasInViewport = ref(false);
    const pictureElement = ref(undefined);

    onMounted(() => {
      const rect = pictureElement.value.getBoundingClientRect();
      const elementIsInViewport =
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        rect.bottom >= 0 &&
        rect.right >= 0;

      if (!elementIsInViewport) return;
      wasInViewport.value = true;
    });

    const { stop } = useIntersectionObserver(
      pictureElement,
      ([{ isIntersecting }], observerElement) => {
        if (!isIntersecting) return;

        wasInViewport.value = true;
        stop();
      },
      {
        rootMargin: '25%',
      }
    );

    return {
      loadEventHook,
      setSizeEventHook,
      pictureElement,
      wasInViewport,
    };
  },

  data() {
    return {
      hasLoaded: false,
      useRatio: false,
      useMaxHeight: false,
      maxHeightObserver: undefined,
      sizeObserver: undefined,
      parentHeight: undefined,
      currentWidth: undefined,
      currentHeight: undefined,
    };
  },

  computed: {
    isSelectingSourceBySize() {
      return [SOURCE_SELECTOR.size, SOURCE_SELECTOR.sizeOrientation].includes(
        this.sourceSelector
      );
    },
    sourceActive() {
      if (!this.isActive) return 'data:,';
      if (this.isSelectingSourceBySize) {
        if (
          this.currentWidth === undefined &&
          this.currentHeight === undefined
        ) {
          return 'data:,,';
        }

        return this.sourceBestFitForSize;
      }

      return this.src;
    },
    sourcesActive() {
      if (!this.isActive) return [];
      if (this.isSelectingSourceBySize) return [];

      return this.sources;
    },
    sortedSources() {
      if (!this.isSelectingSourceBySize) return;
      if (this.selectSizeBy === SELECT_SIZE_BY.auto) {
        const selectSizeBy =
          this.sources[0].width > this.sources[0].height ? 'width' : 'auto';

        return sortBy(this.sources, value => value[selectSizeBy]);
      }
      return sortBy(this.sources, value => value[this.selectSizeBy]);
    },
    sourceBestFitForSize() {
      if (!this.isSelectingSourceBySize) return;

      return (
        this.sortedSources.find(value => {
          const skipHeight =
            value.width > value.height &&
            this.sourceSelector === SOURCE_SELECTOR.sizeOrientation;
          const skipWidth =
            value.height > value.width &&
            this.sourceSelector === SOURCE_SELECTOR.sizeOrientation;

          const isWideEnough = skipWidth || value.width >= this.currentWidth;
          const isHighEnough =
            skipHeight ||
            !value.height ||
            value.height >= (this.limitedCurrentHeight || 0);
          return isWideEnough && isHighEnough;
        })?.srcset || this.src
      );
    },
    limitedCurrentHeight() {
      if (!this.useMaxHeight) return this.currentHeight;

      if (this.currentHeight > this.parentHeight) return this.parentHeight;

      return this.currentHeight;
    },
    isActive() {
      if (!this.lazy) return true;
      return this.wasInViewport;
    },
  },

  watch: {
    wasInViewport: {
      immediate: true,
      handler(wasInViewport) {
        if (!wasInViewport) return;
        this.sizeObserverStart();
      },
    },
  },

  beforeUnmount() {
    this.sizeObserverStop();
  },

  methods: {
    onUIntersectMounted() {
      // this.$nextTick(() => {
      //   this.updateUseRatio();
      //   this.updateUseMaxHeight();
      // });
    },

    onImageLoad(event) {
      this.hasLoaded = true;
      this.$emit('loaded', event);
      this.$nextTick(() => {
        this.loadEventHook(this.$el, event, {
          width: this.currentWidth,
          height: this.currentHeight,
        });
      });
    },

    initSizeObserver() {
      this.updateCurrentSizeByEntries = debounce(
        this.updateCurrentSizeByEntries,
        UPDATE_DEBOUNCE_DELAY
      );

      this.sizeObserver = markRaw(
        new ResizeObserver(this.updateCurrentSizeByEntries)
      );
    },

    /** Starts the resize observer and performs initial update */
    async sizeObserverStart() {
      if (!this.sizeObserver) {
        this.initSizeObserver();
      }
      /* start observer and perform initial update
        in case the sizing was already performed
        at the tick before */
      const targetElement = this.copySizeBy
        ? this.$el.closest(this.copySizeBy)
        : this.$el;

      this.sizeObserver.observe(targetElement);
      const contentRect = targetElement.getBoundingClientRect();
      this.updateCurrentSize(contentRect);
    },

    /** Stops the resize observer */
    sizeObserverStop() {
      if (!this.sizeObserver) return;
      this.sizeObserver.disconnect();
    },

    updateCurrentSize({ width, height }) {
      this.currentWidth = width;
      this.currentHeight = height;
      this.setSizeEventHook({
        width,
        height,
      });
    },
    updateCurrentSizeByEntries(entries) {
      const rect = entries?.[0]?.borderBoxSize[0];
      const width = rect?.width || rect?.inlineSize;
      const height = rect?.heigh || rect?.blockSize;
      this.updateCurrentSize({ width, height });
    },
  },
};
</script>
