// @ts-check
import { defineStore } from 'pinia';

/**
 * Valid types
 *
 * @readonly
 * @enum {string}
 */
export const ITEM_TYPES = Object.freeze({
  event: 'event',
  subscription: 'subscription',
});

/**
 * Persists Items in localStorage and syncs them between tabs
 *
 */
export const useItemsStore = defineStore('items', {
  /**
   *
   * @returns {{ event: Array<string>, subscription: Array<string> }}
   */
  state: () => {
    return {
      event: [],
      subscription: [],
    };
  },
  getters: {
    /**
     * Returns all items for a given type
     */
    items(state) {
      /**
       * @param {ITEM_TYPES} type
       * @returns {Array<string>}
       * */
      return type => {
        if (!this.isValidType(type)) throw 'Invalid type';
        return state[type];
      };
    },

    /**
     * Checks if an item is already included inside a given type
     */
    includes(state) {
      /**
       * @param {ITEM_TYPES} type
       * @param {string} item
       * @returns {Boolean} */
      return (type, item) => {
        if (!this.isValidType(type)) throw 'Invalid type';
        return this.items(type).includes(String(item));
      };
    },

    /**
     * Checks if the given type is valid
     */
    isValidType(state) {
      /**
       * @param {ITEM_TYPES} type
       * @returns {Boolean} */
      return type => {
        return Object.values(ITEM_TYPES).includes(type);
      };
    },
  },
  actions: {
    /**
     * Adds an item to a given type
     *
     * @param {ITEM_TYPES} type
     * @param {string} item
     */
    add(type, item) {
      if (!this.isValidType(type)) throw 'Invalid type';
      this[type].push(String(item));
    },

    /**
     * Removes an item from a given type
     *
     * @param {ITEM_TYPES} type
     * @param {string} item
     */
    remove(type, item) {
      if (!this.isValidType(type)) throw 'Invalid type';
      const index = this[type].indexOf(String(item));
      if (index < 0) return console.error('Item not found');
      this[type].splice(index, 1);
    },

    /**
     * Toggles an item inside a given type
     *
     * @param {ITEM_TYPES} type
     * @param {string} item
     */
    toggle(type, item) {
      if (!this.isValidType(type)) throw 'Invalid type';
      if (this.includes(type, item)) {
        this.remove(type, item);
        return;
      }
      this.add(type, item);
    },

    /**
     * Clears a given type
     *
     * @param {ITEM_TYPES} type
     */
    clear(type) {
      if (!this.isValidType(type)) throw 'Invalid type';
      this[type].splice(0);
    },
  },
  persist: true,
});
