import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { shareStore, unshareAll } from '@stores/utils/shared-state';

import { useItemsStore } from '@stores/items';

export const createStores = ({ app }) => {
  const pinia = createPinia();
  app.use(pinia);

  pinia.use(piniaPluginPersistedstate);

  /*
    möglicherweise scheinen die nachfolgenden zeilen nicht
    100% intuitiv. allerdings bietet `pinia-shared-state`
    via store options keinen cleanup an, den wir aber brauchen
    um den bfcache zu nutzen.
    deswegen müssen wir `share` und `unshare` manuell verwenden.
  */
  const itemStore = useItemsStore();
  shareStore(itemStore, ['event', 'subscription']);
  window.addEventListener('pagehide', unshareAll);

  return pinia;
};
