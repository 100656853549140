import { createStore } from 'vuex';

// import labels from './modules/labels';
import window from './modules/window';
import browser from './modules/browser';
import lightbox from './modules/lightbox';
import popupInfobox from './modules/popup-infobox';

// import overlay from './modules/overlay';
// import popup from './modules/popup';
// import zoombox from './modules/zoombox';
// import scroller from './modules/scroller';
// import header from './modules/header';
// import lightboxGallery from './modules/lightbox-gallery';
// import navUnderline from './modules/nav-underline';
// import shopCart from './modules/shop-cart';

import windowPlugin from './plugins/window.js';
// import labelsPlugin from './plugins/labels.js';
import browserPlugin from './plugins/browser.js';
// import persistencePlugin from './plugins/persistence.js';

const store = createStore({
  modules: {
    // labels,
    window,
    browser,
    // overlay,
    // popup,
    // zoombox,
    // scroller,
    // header,
    lightbox,
    popupInfobox,
    // lightboxGallery,
    // navUnderline,
    // shopCart,
  },

  plugins: [
    windowPlugin,
    //  labelsPlugin,
    browserPlugin,
  ],

  strict: process.env.NODE_ENV !== 'production',
});

export default store;
