<template>
  <template v-if="disabled">
    <slot name="above" />
    <slot name="below" />
  </template>
  <template v-else>
    <div class="floating-layer">
      <div
        v-if="isAboveFixed"
        :style="{
          height: `${heightAbove}px`,
        }"
      ></div>
      <div
        ref="elementAbove"
        class="floating-layer__above"
        :class="{
          'floating-layer__above--fixed': isAboveFixed,
        }"
      >
        <slot name="above" />
      </div>

      <div ref="elementFloating" class="floating-layer__layer">
        <slot />
      </div>

      <div
        v-if="isBelowFixed"
        :style="{
          height: `${heightBelow}px`,
        }"
      ></div>
      <div
        ref="elementBelow"
        class="floating-layer__below"
        :class="{
          'floating-layer__below--fixed': isBelowFixed,
        }"
      >
        <slot name="below" />
      </div>
    </div>
  </template>
</template>

<script setup>
import { gsap } from '@gsap/shockingly';
import { ScrollTrigger } from '@gsap/shockingly/ScrollTrigger';

import { onMounted, ref, onBeforeUnmount, toRefs, markRaw, watch } from 'vue';
import { useElementSize } from '@vueuse/core';

gsap.registerPlugin(
  ScrollTrigger
  // ScrollSmoother
);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});
const { disabled } = toRefs(props);

// const supported = CSS?.supports('position: sticky');

const elementFloating = ref(undefined);
const elementAbove = ref(undefined);
const elementBelow = ref(undefined);

const { height: heightAbove } = useElementSize(elementAbove);
const { height: heightBelow } = useElementSize(elementBelow);
const { height: heightBody } = useElementSize(document.body);

const isAboveFixed = ref(false);
const isBelowFixed = ref(false);

let triggerAbove = undefined;
let triggerBelow = undefined;

onMounted(() => {
  if (disabled.value) return;
  // let scroller = ScrollSmoother.create({
  //   content: '#app',
  //   smooth: 0.2, // how long (in seconds) it takes to "catch up" to the native scroll position
  //   effects: true, // looks for data-speed and data-lag attributes on elements
  //   smoothTouch: 0.1,
  //   normalizeScroll: true,
  // });

  // ScrollTrigger.create({
  //   // scroller: scroller.scrollTrigger.scroller,
  //   trigger: elementFloating.value,
  //   markers: true,
  //   start: 'top bottom',
  //   end: 'bottom top',
  //   snap: {
  //     snapTo: [0, 0.5, 1],
  //   },

  // });
  ScrollTrigger.normalizeScroll(true);

  triggerAbove = markRaw(
    ScrollTrigger.create({
      // scroller: scroller.scrollTrigger.scroller,
      trigger: elementFloating.value,
      // markers: true,
      start: 'top bottom', // TODO: add some safety padding like at below
      end: 'top top',
      onToggle: self => {
        // console.log('> above, toggled, isActive:', self.isActive, self);
        isAboveFixed.value = self.isActive;
      },
    })
  );
  triggerAbove.update();

  triggerBelow = markRaw(
    ScrollTrigger.create({
      // scroller: scroller.scrollTrigger.scroller,
      trigger: elementFloating.value,
      // markers: true,
      start: 'top-=20px top',
      end: 'bottom top',
      onToggle: self => {
        // console.log('> below, toggled, isActive:', self.isActive);
        isBelowFixed.value = self.isActive;
      },
    })
  );
  triggerBelow.update();

  // console.log({ triggerAbove, triggerBelow });
});

watch(heightBody, value => {
  ScrollTrigger.refresh();
});
// onBeforeUpdate(e => {
// });

onBeforeUnmount(() => {
  triggerAbove?.kill();
  triggerBelow?.kill();
});
</script>
