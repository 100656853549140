export const QUIZ_ID = 'quiz-cf';
export const QUIZ_PAGE_ID_PREFIX = `cf-page-${QUIZ_ID}`;
export const GET_QUIZ_PAGE_ID = index => `${QUIZ_PAGE_ID_PREFIX}-${index}`;
export const PAGE_START_ID = 'cf-page-start';
export const PAGE_END_ID = 'cf-page-end';
export const PAGE_GOODIE_AD_ID = 'cf-page-goodie-ad';
export const PAGE_RESULT_ID = 'cf-page-result';
export const RECOMMENDATION_PAGE_ID_PREFIX = 'cf-page-recommendation';
export const GET_RECOMMENDATION_PAGE_ID = index =>
  `${RECOMMENDATION_PAGE_ID_PREFIX}-${index}`;
export const PAGE_FEEDBACK_ID = 'cf-page-feedback';
export const PAGE_CALENDAR_AD_ID = 'cf-page-calendar-ad';
export const QUIZ_STEP_TYPES = Object.freeze({
  text: 'text',
  image: 'image',
});
