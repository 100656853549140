import dayjs from 'dayjs';
import { computed } from 'vue';
import { useLabelsStore } from '@stores/labels';

export const PARAM_KEY = 'page';
export const SPECIAL_MONTH_ID_PAST = 'past';

/**
 * @typedef {Object} CalendarMonth
 * @property {string} id - The id of the month.
 * @property {string} yearMonth - The year and month of the month.
 * @property {string} monthName - The name of the month.
 * @property {boolean} hasContent - Indicates whether the month has content.
 */

/**
 * @typedef {Object} PastCalendarMonth
 * @extends CalendarMonth
 * @property {Array<CalendarMonth>} includedMonths - The months that are included in the past month.
 */

export function useCalendarMonths(
  { dateStart, dateEnd, dateIdList, dateIdListWithContent },
  config
) {
  const labelStore = useLabelsStore();
  const groupPast = config?.groupPast || false;

  const dates = computed(() => {
    return dateIdList.value.map(dateId => dateId.date);
  });

  const datesWithContent = computed(() => {
    return dateIdListWithContent.value.map(dateId => dateId.date);
  });

  const duration = computed(() => {
    const duration = dayjs
      .unix(dateEnd.value)
      .date(28)
      .diff(dayjs.unix(dateStart.value).date(1), 'month', true);
    return Math.max(Math.ceil(Math.abs(duration)), 0);
  });

  const calendarMonths = computed(() => {
    /**
     * @type {Array<CalendarMonth>}
     */
    const months = Array(duration.value)
      .fill(null)
      .map((_, index) => {
        const date = dayjs.unix(dateStart.value).add(index, 'month');
        const dateUnix = String(date.unix());

        return {
          id: date.format('YYYY-MM'),
          label: date.format('MMMM'),
          unix: dateUnix,
          yearMonth: date.format('YYYY-MM'),
          hasContent: monthHasContent(dateUnix),
        };
      });

    if (!groupPast) return months;

    // const hasPastMonth = !!months.find(month => {
    //   const dateUnix = dayjs.unix(month.unix);
    //   const isPastMonth = dateUnix.isBefore(dayjs(), 'month');
    //   return isPastMonth;
    // });

    const hasPastDate = !!dateIdList.value.find(({ date }) => {
      const dateUnix = dayjs.unix(date);
      const isPast = dateUnix.isBefore(dayjs(), 'day');
      return isPast;
    });

    if (!hasPastDate) return months;

    /**
     * @type {PastCalendarMonth}
     */
    const specialMonthPast = {
      id: SPECIAL_MONTH_ID_PAST,
      label: labelStore.get({ path: 'calendarPastEvents' }).label,
      // includedMonths: [],
      includedTeaserIdList: dateIdList.value
        .filter(({ date }) => {
          const dateUnix = dayjs.unix(date);
          const isPast = dateUnix.isBefore(dayjs(), 'day');
          return isPast;
        })
        .map(({ id }) => id),
      hasContent: dateIdListWithContent.value.some(({ date }) => {
        const dateUnix = dayjs.unix(date);
        const isPast = dateUnix.isBefore(dayjs(), 'day');
        return isPast;
      }),
    };

    //   const monthsWithGroupedPast = months.reduce(
    //     (acc, month) => {
    //       const isPastMonth = !monthHasDateInFuture(month.unix);

    //       if (!isPastMonth) {
    //         acc.push(month);
    //         return acc;
    //       }

    //       acc[0].includedMonths.push(month);
    //       acc[0].hasContent = acc[0].hasContent || month.hasContent;

    //       return acc;
    //     },
    //     [specialMonthPast]
    //   );

    // return monthsWithGroupedPast;

    // alternative version without grouping, using includedTeaserIdList instead of months

    const monthsWithoutPastMonths = months.filter(month =>
      monthHasDateInFuture(month.unix)
    );

    return [specialMonthPast, ...monthsWithoutPastMonths];
  });

  const monthHasContent = date => {
    return !(
      datesWithContent.value.findIndex(activeDate => {
        return dayjs.unix(date).isSame(dayjs.unix(activeDate), 'month');
      }) === -1
    );
  };

  const monthHasDateInFuture = date => {
    return !(
      dates.value.findIndex(activeDate => {
        return (
          dayjs.unix(date).isSame(dayjs.unix(activeDate), 'month') &&
          dayjs().isBefore(dayjs.unix(activeDate))
        );
      }) === -1
    );
  };

  return { calendarMonths };
}
