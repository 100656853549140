const state = {
  breakpoints: {
    mobile: 0,
    tablet: 768,
    navigation: 1240,
    desktop: 1240,
    'teaser-scroller': 1680,
  },
  innerWidth: 0,
  innerHeight: 0,
  scrollX: 0,
  scrollY: 0,
};

const mutations = {
  updateHeight(state, innerHeight) {
    state.innerHeight = innerHeight;
  },
  updateWidth(state, innerWidth) {
    state.innerWidth = innerWidth;
  },
  updateScroll(state, { scrollX, scrollY }) {
    state.scrollX = scrollX;
    state.scrollY = scrollY;
  },
};

const getters = {
  scrollY: state => {
    return state.scrollY;
  },
  scrollX: state => {
    return state.scrollX;
  },
  innerWidth: state => {
    return state.innerWidth;
  },
  innerHeight: state => {
    return state.innerHeight;
  },
  breakpointKeys: state => {
    return Object.keys(state.breakpoints);
  },
  breakpointList: state => {
    return state.breakpoints;
  },
  breakpoint: state => {
    return Object.keys(state.breakpoints).reduce((prev, curr) =>
      state.breakpoints[curr] <= state.innerWidth ? curr : prev
    );
  },
  breakpointUp: (state, getters) => breakpoint => {
    const currentWidth = state.breakpoints[getters.breakpoint];
    const testWidth = state.breakpoints[breakpoint];
    return currentWidth >= testWidth;
  },
  breakpointDown: (state, getters) => breakpoint => {
    const currentWidth = state.breakpoints[getters.breakpoint];
    const testWidth = state.breakpoints[breakpoint];
    return currentWidth < testWidth;
  },
  breakpointOnly: (state, getters) => breakpoint => {
    const currentWidth = state.breakpoints[getters.breakpoint];
    const testWidth = state.breakpoints[breakpoint];
    return currentWidth === testWidth;
  },
  /*
    accepts configurations like
    {
      something: false,
      items: 1,
      breakpoints: {
        sm: { items: 2 },
        xl: { items: 3 },
      }
    }

    example output, for sm:
    { something: false, items: 2}

    example output, for md:
    { something: false, items: 2}

    example output, for xl:
    { something: false, items: 3}
  */
  mergeConfigByBreakpoint: (state, getters) => configInitial => {
    let config = configInitial;

    if (!configInitial.breakpoints) {
      return { lastValidBreakpoint: undefined, config };
    }

    let lastValidBreakpoint = undefined;

    getters.breakpointKeys.forEach(breakpoint => {
      const configBreakpoint = configInitial.breakpoints[breakpoint];
      if (configBreakpoint && getters.breakpointUp(breakpoint)) {
        lastValidBreakpoint = breakpoint;
        config = { ...config, ...configBreakpoint };
      }
    });

    delete config.breakpoints;

    return { lastValidBreakpoint, config };
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
