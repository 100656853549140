import { createApp, defineAsyncComponent, configureCompat } from 'vue';
import UUID from 'vue-uuid';
import PortalVue from 'portal-vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { createI18n } from 'vue-i18n';

import store from './store';
import { createStores } from '@stores';
import './directives';

configureCompat({ ATTR_FALSE_VALUE: false, TRANSITION_GROUP_ROOT: false });

function getNameByPath(path) {
  if (!path) return;

  const name = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
  const nameFormatted = upperFirst(camelCase(name));

  return nameFormatted;
}

export const app = createApp({});
createStores({ app });

const i18n = createI18n({
  legacy: false,
  locale: document.documentElement.lang || 'de',
  fallbackLocale: 'de',
});
app.use(i18n);

app.use(store);

const importedFiles = [];

import importGroupUtilities from './import-groups/utilities';
import importGroupAboveFold from './import-groups/above-fold';

const importGroupsComponents = [
  ...importGroupUtilities,
  ...importGroupAboveFold,
];

importGroupsComponents.forEach(component => {
  const componentName = getNameByPath(component.__file);

  if (importedFiles.includes(componentName)) return;
  importedFiles.push(componentName);

  app.component(componentName, component);
});

// import(
//   /* webpackChunkName: "scrollers", webpackMode: "lazy" */
//   './import-groups/scrollers'
// ).then(({ default: components }) => {
//   components.forEach(component => {
//     app.component(getNameByPath(component.__file), component);
//   });
// });

const ComponentContext = require.context(
  '@components',
  true,
  /\.vue$/i,
  'lazy'
);

ComponentContext.keys().forEach(filePath => {
  const componentName = getNameByPath(filePath);
  if (importedFiles.includes(componentName)) return;

  app.component(
    componentName,
    defineAsyncComponent({ loader: () => ComponentContext(filePath) })
  );
});

app.use(UUID);
app.use(PortalVue);

app.config.compilerOptions.whitespace = 'condense';

app.mount('#app');

setTimeout(() => {
  // somehow a manual scroll to the anchor element
  // is needed, to correct the scroll position
  // after the vue "app" is mounted
  const anchor = window.location.hash;
  if (!anchor) return;
  const anchorElement = document.querySelector(anchor);
  if (!anchorElement) return;
  anchorElement.scrollIntoView({ top: 0 });
}, 0);

/* eslint-disable-next-line */
window.___VM___ = app;
