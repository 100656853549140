import Vue from 'vue';
import { ClickRedirect } from '@js/modules/click-redirect';

function getConfigByBreakpoint(configInitial, $store) {
  const mergeConfig = $store.getters['window/mergeConfigByBreakpoint'];
  const config = mergeConfig(configInitial).config;
  return config;
}

function getConfig(configInitial, $store) {
  let config = configInitial;
  const configPreset = config.configPreset;
  if (configPreset) {
    config = { ...configInitial, ...CONFIG_PRESETS[configPreset] };
  }
  return getConfigByBreakpoint(config, $store);
}

const CONFIG_PRESETS = {
  'teaser-default': {
    parent: 'article[class^="teaser-"]',
    selector: 'a',
  },
  'calendar-event': {
    parent: '.calendar-event',
    selector: '.calendar-event__link',
  },
  'socialmedia-teaser': {
    parent: '.socialmedia-teaser',
    selector: 'a',
  },
  'styled-figure-lightbox': {
    parent: 'figure.styled-figure',
    selector: '.styled-figure__button-lightbox',
  },
  'picture-gallery-lightbox': {
    parent: '.picture-gallery__item',
    selector: '.picture-gallery__button-lightbox',
  },
  'video-teaser': {
    parent: '.video-teaser',
    selector: 'a',
  },
};

const activeHooks = new Map();

Vue.directive('click-redirect', {
  mounted(el, binding, vnode) {
    if (!binding.value) return;

    const $store = vnode.context.$store;
    const config = getConfig(binding.value, $store);
    const clickRedirect = new ClickRedirect(el, config);
    activeHooks.set(el, clickRedirect);

    if (config.isDisabled === true) {
      clickRedirect.deactivate();
    } else {
      clickRedirect.activate();
    }
  },
  updated(el, binding, vnode) {
    if (!binding.value) return;

    const clickRedirect = activeHooks.get(el);
    const $store = vnode.context.$store;
    const config = getConfig(binding.value, $store);
    clickRedirect.updateConfig(config);

    if (config.isDisabled === true) {
      clickRedirect.deactivate();
    } else {
      clickRedirect.activate();
    }
  },
  unmounted(el) {
    const clickRedirect = activeHooks.get(el);
    clickRedirect?.deactivate();
    activeHooks.delete(el);
  },
});
